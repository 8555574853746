/* eslint-disable no-console */
import { LogLevel } from '@azure/msal-browser';

export const b2cPolicies = {
	names: {
		signUpSignIn: 'B2C_1_susi_v2',
		forgotPassword: 'B2C_1_reset_v3',
		editProfile: 'B2C_1_edit_profile_v2',
	},
	authorities: {
		signUpSignIn: {
			authority: `https://${process.env.REACT_APP_domain_name}/${process.env.REACT_APP_azure_tenant_id}/b2c_1a_signin_oidc_row`,
		},
		forgotPassword: {
			authority: `https://${process.env.REACT_APP_domain_name}/${process.env.REACT_APP_azure_tenant_id}/b2c_1a_signin_oidc_row`,
		},
		editProfile: {
			authority: `https://${process.env.REACT_APP_domain_name}/${process.env.REACT_APP_azure_tenant_id}/b2c_1a_signin_oidc_row`,
		},
	},
	authorityDomain: `https://${process.env.REACT_APP_domain_name}/`,
};

export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_dtiam_client_id, // This is the ONLY mandatory field that we need to supply.
		authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
		knownAuthorities: [process.env.REACT_APP_domain_name], // Mark your B2C tenant's domain as trusted.
		redirectUri: '/partsexl',
		postLogoutRedirectUri: '/logout',
	},
	cache: {
		cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
					default:
						console.info(message);
				}
			},
		},
	},
};

export const loginRequest = {
	scopes: ['openid', 'offline_access'],
};
