export const baseURL = process.env.REACT_APP_BASEURL;

export const reseller = {
	allResellerList: '/reseller/getResellersList',

	resellerByCountryist:
		'/reseller/getResellersList?selectedCountry=:selectedCountry',
};

export const mpc = {
	search: '/mpc/getMpcByFilte/:selectedCriteria',
	getAllCountries: '/common/getAllCountries',
	getAllCountriesIsoInternational: '/common/getAllCountriesIsoInternational',
	getContactList: '/mpc/maintainContact/getMpcByMarket',
	addContact: '/mpc/maintainContact/addMpc',
	deleteContact: '/mpc/maintainContact/deleteMpcById',
	getContactById: '/mpc/maintainContact/getMpcById',
	editContact: '/mpc/maintainContact/editMpc',
	getMpcExcel: '/mpc/searchContact/export/getMpcExcel',
	getMpcCsv: '/mpc/searchContact/export/getMpcCsv',
	dataReview: '/mpc/dataReview',
	getDataReviewExcel: '/mpc/getDataReviewExcel',
};
export const userService = {
	userInfo: '/userInfo',
};
export const common = {
	addReseller: '/common/saveReseller',
	searchReseller: '/common/searchReseller?Name=:Name',
	editResellerList: '/common/getEditResellerList',
	getResellerById: '/common/getResellerById',
	editReseller: '/common/editReseller',
	getResellerHistory: '/common/getResellerHistoryExcelById',
	deleteEvidence: '/common/deleteEvidenceById',
	xsl: '/common/getResellersListExcel',
	csv: '/common/getResellersListCsv',
	verifyResellerList: '/common/getVerifyResellerList',
	verifyResellerExcel: '/common/getVerifyResellerListExcel',
	verifyResellerCSV: '/common/getVerifyResellerListCsv',
	getDocumentsByFlag: '/common/getDocumentsByFlag',
	downloadDocumentById: '/common/downloadDocumentById',
	uploadDocumentById: '/common/uploadDocumentById',
	deleteDocumentById: '/common/deleteDocumentById',
	deleteResellerById: '/common/deleteResellerById',
	getEvidenceById: '/common/getEvidenceById',
};
export const hq = {
	kpiReport: '/hq/kpiReporting',
	exportKPI: '/hq/kpiReportingExcel',
	searchContact: '/hq/maintainContact/SearchCreateria',
	addContact: '/hq/maintainContact/addMpc',
	deleteContact: '/hq/maintainContact/deleteMpcById',
	getContactById: '/hq/maintainContact/getMpcById',
	editContact: '/hq/maintainContact/editMpc',
	getMpcExcel: '/hq/maintainContact/export/getMpcExcel',
	getMpcCsv: '/hq/maintainContact/export/getMpcCsv',
	dataReview: '/hq/dataReview',
	getDataReviewExcel: '/hq/getDataReviewExcel',
};
