import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import DownloadTable from '../../CommonComponents/Table/DownloadTable';
import { getDocumentsByFlag } from '../../Services/resellerServices';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import Loader from '../../CommonComponents/Loader/Loader';
import CustomPagination from '../../CommonComponents/Table/CustomPagination';

function DownloadSection() {
	const { section } = useParams();
	const { t } = useTranslation();
	const [tableData, setTableData] = useState([]);
	const [openError, setOpenError] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [resultLoading, setResultLoading] = useState(false);
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const downloadOptions = [
		{
			label: t('TemplatesProcessinstruction'),
			path: 'PIF',
		},
		{ label: t('TemplatesSampleLetter'), path: 'SLD' },
		{ label: t('TemplatesDeclarationFleet'), path: 'DUF' },
		{
			label: t('TemplatesDeclarationReuser'),
			path: 'URU',
		},
		{ label: t('TemplatesBodyBuilders'), path: 'BOB' },
		{ label: t('TemplatesReporting'), path: 'RPT' },
		{ label: t('TemplatesFAQ'), path: 'FAQ' },
	];
	const sectionLabel =
		downloadOptions.find((option) => option.path === section)?.label || section;
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	useEffect(() => {
		setResultLoading(true);
		getDocumentsByFlag(section, 0, 10)
			.then((data) => {
				setTableData(data?.result);
				setResultLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setResultLoading(false);
				setApiError(t('apiError'));
				setOpenError(true);
			});
	}, [section]);
	const handlePageChange = async (pageNo) => {
		try {
			setPageNum(pageNo - 1);
			setResultLoading(true);
			const data = await getDocumentsByFlag(section, pageNo - 1, 10);
			setTableData(data?.result);
			setResultLoading(false);
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	const refreshData = async () => {
		try {
			setResultLoading(true);
			const data = await getDocumentsByFlag(section, pageNum, 10);
			setTableData(data?.result);
			setResultLoading(false);
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	return (
		<div className="downloadSection">
			{/* Breadcrumb */}
			<nav>
				<Link to="/download" className="downloadLink">
					{t('navlevel1_2')}
				</Link>
				<b>/</b>
				<span className="highlightedOption">{sectionLabel}</span>
			</nav>
			<div className="downloadTableSection">
				<DownloadTable
					tableData={tableData?.documents}
					refreshData={refreshData}
				/>
			</div>
			<div className="pagination">
				{tableData?.documents?.length !== 0 && (
					<CustomPagination
						total={tableData.length}
						totalRecords={tableData?.totalNumberOfRecord}
						handlePageChange={handlePageChange}
						pageSize={pageSize}
						pageNo={pageNum}
						totalPages={tableData?.numberOfPages}
					/>
				)}
			</div>
			<br />
			<br />
			<ErrorToast
				open={openError}
				handleClose={handleCloseError}
				message={apiError}
			/>
			<Loader open={resultLoading} text={'Loading'} />
		</div>
	);
}

export default DownloadSection;
