import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Checkbox,
	IconButton,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import ContactContext from '../../Modules/MaintainContact/ContactContext';
import { Trash2, Edit } from 'react-feather';

const CustomTable = (props) => {
	const colheaders = props?.colheaders;
	const { contacts, setContacts } = useContext(ContactContext);
	const width = props?.width ? `${props?.width}rem` : 'auto';
	return (
		<Grid>
			<Grid item sx={{ width: width }}>
				<TableContainer
					component={Paper}
					sx={{
						overflow: 'auto',
						'&::-webkit-scrollbar': { width: '12px', height: '12px' },
						'&::-webkit-scrollbar-track': {
							background: '#f1f1f1',
							margin: '40px',
							boxShadow: 'inset 7px 10px 12px #f0f0f0',
						},
						'&::-webkit-scrollbar-thumb': {
							background: '#ddd',
							borderRadius: '1rem',
						},
						'&::-webkit-scrollbar-thumb:hover': { background: '#888' },
					}}
				>
					<Table>
						<TableHead sx={{ backgroundColor: '#dddddd54' }}>
							<TableRow>
								{props?.page === 'contact/edit' && (
									<TableCell
										align="center"
										sx={{
											fontWeight: 'bold',
											borderRight: '1px solid #ccc',
										}}
									></TableCell>
								)}
								{colheaders?.map((header) => (
									<TableCell
										align="center"
										key={header.key}
										sx={{
											fontWeight: 'bold',
											borderRight: '1px solid #ccc',
										}}
									>
										{header.name}
									</TableCell>
								))}
								{props?.page && (
									<TableCell
										align="center"
										sx={{
											fontWeight: 'bold',
											borderRight: '1px solid #ccc',
										}}
									>
										Action
									</TableCell>
								)}
							</TableRow>
						</TableHead>

						<TableBody>
							{props?.tableData?.length === 0 ||
							props?.errorMessage?.length !== 0 ? (
								<TableRow>
									<TableCell></TableCell>
								</TableRow>
							) : (
								props?.tableData?.map((data, index) => (
									<TableRow key={index}>
										{props?.page === 'contact/edit' && (
											<TableCell
												align="center"
												sx={{
													borderRight: '1px solid #ccc',
												}}
											>
												<Checkbox
													onChange={(e) => {
														if (e.target.checked) {
															setContacts([...contacts, data?.mpcid]);
														} else {
															const filteredIds = contacts.filter(
																(contact) => contact !== data?.mpcid
															);
															setContacts(filteredIds);
														}
														console.log(data.mpcid);
													}}
												/>
											</TableCell>
										)}
										{colheaders.map((header) => (
											<TableCell
												align="center"
												sx={{
													borderRight: '1px solid #ccc',
												}}
											>
												{header.key === 'divisionalfocus'
													? data[header.key]?.replace('||', ',')
													: data[header.key]}
											</TableCell>
										))}
										{props?.page && (
											<TableCell align="center" sx={{ width: 'auto' }}>
												<Link
													to={
														props?.page === 'contact/edit'
															? `/${props?.page}/${data?.mpcid}`
															: `/${props?.page}/${data?.resellerid}`
													}
												>
													<IconButton color="#000">
														<Edit color="#000" />
													</IconButton>
												</Link>
												{props?.page === 'reseller/edit' && (
													<IconButton
														color="#000"
														onClick={() => {
															props?.handleDelete(data?.resellerid);
														}}
													>
														<Trash2 color="#000" />
													</IconButton>
												)}
											</TableCell>
										)}
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>

			<br />
		</Grid>
	);
};

export default CustomTable;
