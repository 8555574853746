import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import EN from '../../Assets/EN_User Manual PartsExList.pdf';
import DE from '../../Assets/DE_Benutzerhandbuch PartsExList.pdf';
import './Help.css';

const Help = () => {
	const { t } = useTranslation();
	return (
		<div className="HelpWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_3')}
			</Typography>
			<hr />
			<ul>
				<li>
					<Typography
						noWrap
						sx={{
							color: '#151C2D',
							fontSize: '14px',
							fontStyle: 'normal',
							fontWeight: 600,
							fontFamily: 'Arial, Helvetica, sans-serif',
						}}
					>
						<a
							href={EN}
							download
							style={{ color: '#000', textDecoration: 'none' }}
						>
							{'EN User Manual'}
						</a>
					</Typography>
				</li>

				<li>
					<Typography
						noWrap
						sx={{
							color: '#151C2D',
							fontSize: '14px',
							fontStyle: 'normal',
							fontWeight: 600,
							fontFamily: 'Arial, Helvetica, sans-serif',
						}}
					>
						<a
							href={DE}
							download
							style={{ color: '#000', textDecoration: 'none' }}
						>
							{'DE Benutzerhandbuch'}
						</a>
					</Typography>
				</li>
			</ul>
		</div>
	);
};

export default Help;
